import PageViewEvent from "../events/PageViewEvent";
import ProductViewEvent from "../events/ProductViewEvent";
import fetchProductData from "../../data/fetcher/product";

/**
 * @param {Tracker} tracker
 */
export default async function (tracker) {
    const href = window.location.href;
    const pageViewEvent = new PageViewEvent(href);

    tracker.trackEvent(pageViewEvent)

    if (href.includes("/products")) {
        try {
            const product = await fetchProductData(href);
            if (product) {
                const productViewEvent = new ProductViewEvent(
                    href, product, window?.Shopify?.currency.active
                );

                tracker.trackEvent(productViewEvent)
            }
        } catch (error) {
            console.error("Error fetching product data", error);
        }
    }
}
