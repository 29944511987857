import AbstractEvent from "./AbstractEvent";

export default class PageViewEvent extends AbstractEvent {

    /**
     * @param {string} url
     */
    constructor(url) {
        super();

        this.url = url;
    }
}
