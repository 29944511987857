import AbstractEvent from "./AbstractEvent";

export default class InitiateCheckoutEvent extends AbstractEvent {

    /**
     * @param {Object} cart
     */
    constructor(cart) {
        super();

        this.cart = cart;
    }
}
