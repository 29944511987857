export class PixelCollection extends Array {

    duplicateServerSideEventsAsClientSideEvents() {
        this.serverSideAsClientSide = true;
    }

    /**
     * @return {{type, code, token, testCode}[]}
     */
    all() {
        return this;
    }

    /**
     * @return {{type, code, token, testCode}[]}
     */
    onlyClientSide() {
        return this.filter(pixel => pixel.token === '' || this.serverSideAsClientSide);
    }

    /**
      * @return {{type, code, token, testCode}[]}
     */
    onlyServerSideSide() {
        return this.filter(pixel => pixel.token !== '');
    }

    /**
     * @return {boolean}
     */
    hasClientSide() {
        if (! this.hasClientSideCache) {
            this.hasClientSideCache = this.onlyClientSide().length > 0;
        }
        return this.hasClientSideCache;
    }

    /**
     * @return {boolean}
     */
    hasServerSide() {
        if (! this.hasServerSideCache) {
            this.hasServerSideCache = this.onlyServerSideSide().length > 0;
        }
        return this.hasServerSideCache;
    }
}
