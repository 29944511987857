import AbstractPixel from "../pixels/implementation/AbstractPixel";
import Facebook from "../pixels/implementation/Facebook";
import TikTok from "../pixels/implementation/TikTok";
import {groupBy} from "../helpers/object";
import {PixelCollection} from "../pixels/PixelCollection";

export default class Tracker {

    /**
     * Add new implementations here
     *
     * @type {{facebook: Facebook, tiktok: TikTok}}
     */
    static TYPE_TO_IMPLEMENTATION_MAP = {
        "facebook": Facebook,
        "tiktok": TikTok,
    }

    /**
     * @param {{type, code, token, testCode}[]} pixelsData
     * @param {Context} context
     * @param {UserData} userData
     */
    constructor(pixelsData, context, userData = null) {
        this.pixels = [];

        this.initPixelObjects(pixelsData, context, userData);
        this.performInitPixelMethods();
    }

    /**
     * @private
     * @param {{type, code, token, testCode}[]} pixelsData
     * @param {Context} context
     * @param {UserData} userData
     */
    initPixelObjects(pixelsData, context, userData) {
        const groupedPixelsData = groupBy(
            pixelsData,
            pixelData => pixelData.type
        );

        for (const [type, pixelsDataGroup] of Object.entries(groupedPixelsData)) {
            const collection = new PixelCollection(...pixelsDataGroup);

            if (Tracker.TYPE_TO_IMPLEMENTATION_MAP[type]) {
                this.pixels.push(
                    new (Tracker.TYPE_TO_IMPLEMENTATION_MAP[type])(type, collection, userData, context)
                );
            } else {
                this.pixels.push(
                    new AbstractPixel(type, collection, userData, context)
                );
            }
        }
    }

    /**
     * @private
     */
    performInitPixelMethods()
    {
        this.pixels.forEach((pixel) => {
            pixel.initPixel();
        });
    }

    /**
     * @param {AbstractEvent} event
     */
    trackEvent(event)
    {
        this.pixels.forEach((pixel) => {
            pixel.trackEvent(event);
        });
    }
}
