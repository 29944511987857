import AbstractEvent from "./AbstractEvent";

export default class ProductViewEvent extends AbstractEvent {

    /**
     * @param {string} url
     * @param {Object} product
     * @param {string} currency
     */
    constructor(url, product, currency) {
        super();

        this.url = url;
        this.product = product;
        this.currency = currency;
    }
}
