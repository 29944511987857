import AbstractPixel from "./AbstractPixel";
import SHA256 from "../../helpers/sha256";
import PageViewEvent from "../../tracker/events/PageViewEvent";
import ProductViewEvent from "../../tracker/events/ProductViewEvent";
import AddToCartEvent from "../../tracker/events/AddToCartEvent";
import InitiateCheckoutEvent from "../../tracker/events/InitiateCheckoutEvent";
import {getTimestamp} from "../../helpers/time";
import {insertScriptInHead} from "../../helpers/dom";
import {normalizePrice} from "../../helpers/price";
import {sendDataToServer} from "../../helpers/server-side";
import CheckoutCompletedEvent from "../../tracker/events/CheckoutCompletedEvent";
import {trim} from "../../helpers/strings";
import {getCookie} from "../../helpers/cookie";

/**
 * @see client-side https://developers.facebook.com/docs/meta-pixel/get-started
 * @see server-side https://developers.facebook.com/docs/marketing-api/conversions-api/get-started
 */
export default class Facebook extends AbstractPixel {

    /**
     * @param dispatcher
     */
    subscribe(dispatcher) {
        dispatcher.listen(PageViewEvent, this.onPageView);
        dispatcher.listen(ProductViewEvent, this.onProductViewEvent);
        dispatcher.listen(AddToCartEvent, this.onAddToCartEvent);
        dispatcher.listen(InitiateCheckoutEvent, this.onInitiateCheckoutEvent);
        dispatcher.listen(CheckoutCompletedEvent, this.onCheckoutCompletedEvent);
    }

    /**
     * @public
     */
    initPixel() {
        /**
         * Warning! For the best ad performance, TikTok recommend advertisers set up both
         * TikTok Pixel SDK (without AcessToken) and Events API (with AccessToken) to ensure maximum data coverage.
         * Deduplication by `event_id`
         */
        this.pixelCollection.duplicateServerSideEventsAsClientSideEvents();

        if (! this.pixelCollection.hasClientSide()) {
            return;
        }

        let code =
            `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');`;

        const userData = this.generateUserData();

        this.pixelCollection.onlyClientSide().forEach(pixel => {
            code += ` fbq('init', '${pixel.code}', ${JSON.stringify(userData)});`;
        });

        insertScriptInHead(code);
    }

    /**
     * @private
     * @param {number} price
     * @return {string}
     */
    formatPrice(price) {
        return (price / 100).toFixed(2);
    }

    /**
     * @param {PageViewEvent} event
     */
    onPageView(event) {
        this.push('PageView', event.eventId, {});
    }

    /**
     * @param {ProductViewEvent} event
     */
    onProductViewEvent(event) {
        const product = event.product;

        const viewContentData = {
            content_ids: [`${product.id}`],
            content_type: "product_group",
            value: normalizePrice(product.price),
            content_name: product.title,
            currency: event.currency,
            content_category: product["type"] || "Default Category",
        };

        this.push('ViewContent', event.eventId, viewContentData);
    }

    /**
     *
     * @param {AddToCartEvent} event
     */
    onAddToCartEvent(event) {
        const cartItem = event.item;
        const cart = event.cart;

        const payload = {
            content_ids: [`${cartItem.id}`],
            content_type:'product_group',
            value: this.formatPrice(cartItem.price),
            content_name: cartItem.title,
            currency: cart.currency,
            content_category: cartItem?.product_type,
            num_items: 1,
        };

        this.push('AddToCart', event.eventId, payload);
    }

    /**
     * @param {InitiateCheckoutEvent} event
     */
    onInitiateCheckoutEvent(event) {
        const cart = event.cart

        const payload = {
            content_ids: cart.items.map((item) => `${item.id}`),
            content_type:'product_group',
            value: this.formatPrice(cart.total_price),
            currency: cart.currency,
            num_items: cart.item_count,
        };

        this.push('InitiateCheckout', event.eventId, payload);
    }

    /**
     * @param {CheckoutCompletedEvent} event
     */
    onCheckoutCompletedEvent(event) {
        const shopifyCheckoutEvent = event.shopifyCheckoutCompletedEvent;

        const itemIds = shopifyCheckoutEvent.data.checkout.lineItems
                .map(item => item.variant?.product?.id.toString()).filter(Boolean);

        const payload = {
            content_type: 'product_group',
            content_ids: itemIds,
            num_items: itemIds.length,
            currency: shopifyCheckoutEvent.data.checkout.totalPrice.currencyCode,
            value: shopifyCheckoutEvent.data.checkout.totalPrice.amount,
        };

        this.push('Purchase', event.eventId, payload);
    }

    /**
     * @return {{external_id: (string|*)}}
     */
    generateUserData() {
        const data = {
            external_id: this.resolveUserId()
        };
        if (this.userData?.firstname) { data.fn = SHA256(trim(this.userData.firstname.toLowerCase())); }
        if (this.userData?.lastname)  { data.ln = SHA256(trim(this.userData.lastname.toLowerCase())); }
        if (this.userData?.email)     { data.em = SHA256(trim(this.userData.email.toLowerCase())); }
        if (this.userData?.phone)     { data.ph = SHA256(trim(this.userData.phone.toLowerCase())); }
        if (this.userData?.city)      { data.ct = SHA256(this.userData.city); }
        if (this.userData?.country)   { data.country = SHA256(this.userData.country); }
        if (this.userData?.zip)       { data.zp = SHA256(this.userData.zip); }

        return data;
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    push(eventName, eventId, payload) {

        // client-side
        if (this.pixelCollection.hasClientSide()) {
            this.writeToDOM(eventName, eventId, payload);
        }

        // server-side
        if (this.pixelCollection.hasServerSide()) {
            this.sendToServer(eventName, eventId, payload);
        }
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    writeToDOM(eventName, eventId, payload) {
        /**
         * Only one fbq('track') method call is needed for all client-side pixels
         */
        const code =
            `fbq('track', '${eventName}', ${JSON.stringify(payload)}, {eventID: '${eventId}'});`;

        insertScriptInHead(code);
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    sendToServer(eventName, eventId, payload) {
        const userData = this.generateUserData();
        const clickIdParams = {
            fbp: getCookie('_fbp') || undefined,
            fbc: getCookie('_fbc') || undefined,
        };

        this.pixelCollection.onlyServerSideSide().forEach(pixel => {
            const timestamp = getTimestamp();
            const body = {
                data: [{
                    event_name: eventName,
                    event_id: eventId,
                    event_time: timestamp,
                    event_source_url: this.context.url,
                    action_source: "website",
                    user_data: {
                        client_ip_address: 0,
                        client_user_agent: this.context.userAgent,
                        ...userData,
                        ...clickIdParams
                    },
                    ...payload
                }],
            }
            if (pixel.testCode !== "") {
                body.test_event_code = pixel.testCode;
            }

            sendDataToServer(body, pixel.type, pixel.code, pixel.token);
        });
    }
}
