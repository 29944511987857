import {RequestsObserver} from "../../services/RequestsObserver";
import {sellButtonsSelector} from "../../helpers/selectors";
import AddToCartEvent from "../events/AddToCartEvent";
import fetchCartData from "../../data/fetcher/cart";

/**
 * @param {Tracker} tracker
 */
export default function (tracker) {

    const dispatchEvent = async function (variantId) {
        const cart = await fetchCartData();
        const cartItem = cart.items.find((item) => item["variant_id"].toString() === variantId.toString());

        const event = new AddToCartEvent(cartItem, cart);

        tracker.trackEvent(event);
    };

    if (window.Shopify?.shop === "a0d72b-07.myshopify.com") {
        // XHR/Fetch Observers flow
        new RequestsObserver([
            {
                pattern: "^\\/cart\\/add",
                handler: function (response) {
                    try {
                        let jsonData = JSON.parse(response);
                        if (jsonData.variant_id) {
                            dispatchEvent(jsonData.variant_id);
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }
            },
        ]);
    } else {
        // basic flow
        document.body.addEventListener("click", function (event) {
            if (event.target.matches(sellButtonsSelector)) {
                const parentElement = event.target.closest("form");
                if (parentElement) {
                    const variantInput = parentElement.querySelector('input[name="id"]');
                    const variantId = variantInput ? variantInput.value : null;
                    if (variantId) {
                        setTimeout(function () {
                            dispatchEvent(variantId);
                        }, 1000);
                    }
                }
            }
        });
    }
}
