import {checkoutButtonsSelector} from "../../helpers/selectors";
import InitiateCheckoutEvent from "../events/InitiateCheckoutEvent";
import fetchCartData from "../../data/fetcher/cart";

/**
 * @param {Tracker} tracker
 */
export default function (tracker) {
    document.body.addEventListener("click", function (event) {
        if (event.target.matches(checkoutButtonsSelector)) {
            try {
                const data = fetchCartData();
                if (data) {
                    const event = new InitiateCheckoutEvent(data);
                    tracker.trackEvent(event);
                }
            } catch (error) {
                console.error("Error fetching cart data", error);
            }
        }
    });

}
