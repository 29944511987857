import AbstractEvent from "./AbstractEvent";

export default class CheckoutCompletedEvent extends AbstractEvent {

    /**
     * @param {Object} shopifyCheckoutCompletedEvent
     */
    constructor(shopifyCheckoutCompletedEvent) {
        super();

        this.shopifyCheckoutCompletedEvent = shopifyCheckoutCompletedEvent;
    }
}
