import {trimLeft} from "../../helpers/strings";

export class RequestsObserver {

    /**
     * @param urlSubscribers Array
     */
    constructor(urlSubscribers) {
        this.subscribers = urlSubscribers;
        this.fetch = window.fetch;
        this.xmlHttpRequestSend = window.XMLHttpRequest.prototype.send;

        this.registerFetchObserver();
        this.registerXhrObserver();
    }

    registerFetchObserver() {
        let self = this;

        window.fetch = function () {
            let promise = self.fetch.apply(null, arguments);

            if (typeof arguments[0] === "string") {
                let handler = self.resolveUrlHandler(arguments[0]);
                if (handler) {
                    promise.then((response) => {
                        if (response.ok) {
                            response.clone().text()
                                .then((data) => {
                                    handler.apply(null, [data])
                                })
                        }
                    });
                }
            }

            return promise;
        };
    }

    registerXhrObserver() {
        let self = this;

        window.XMLHttpRequest.prototype.send = function () {
            this.addEventListener("load", function(event) {
                let handler = self.resolveUrlHandler(event.target._url);
                if (handler) {
                    handler.apply(null, [event.target.response]);
                }
            });

            return self.xmlHttpRequestSend.apply(this, arguments);
        }
    }

    /**
     * @param url String
     * @returns {null|Function}
     */
    resolveUrlHandler(url) {
        let origin = window.location.origin + window.Shopify?.routes?.root,
            path = trimLeft(
                url.replace(origin, ''), '/'
            );

        try {
            let subscriber = this.subscribers.find(subscriber => {
                let regExp = new RegExp(subscriber.pattern, 'g')
                return path && regExp.exec('/' + path);
            });
            if (subscriber) {
                return subscriber.handler;
            }
        } catch (error) {
            console.error(error)
        }

        return null;
    }
}
