import AbstractEvent from "./AbstractEvent";

export default class AddToCartEvent extends AbstractEvent {

    /**
     * @param {Object} item
     * @param {Object} cart
     */
    constructor(item, cart) {
        super();

        this.item = item;
        this.cart = cart;
    }
}
