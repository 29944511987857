import {getTimestamp} from "./time";
import SHA256 from "./sha256";

export const trim = (string, char) => {
  string = trimLeft(string, char);
  string = trimRight(string, char);   

  return string;
};

export const trimLeft = (string, char) => {
  while (string.charAt(0) === char) {
    string = string.substring(1);
  }

  return string;
};

export const trimRight = (string, char) => {
  while (string.charAt(string.length - 1) === char) {
    string = string.substring(0, string.length - 1);
  }

  return string;
};

export const caseCompare = (str1, str2) => {
  const string1 = (str1 + '').toLowerCase(),
    string2 = (str2 + '').toLowerCase();

  if (string1 > string2) {
    return 1
  } else if (string1 === string2) {
    return 0
  }

  return -1
};

export const escapeHtml = (str) => {
  const text = document.createTextNode(str),
    p = document.createElement('p');
  p.appendChild(text);

  return p.innerHTML;
};

export const isSame = (str1, str2) => caseCompare(str1, str2) === 0;

export function generateRandomString(length) {
  const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function generateUUID() {
  return  SHA256(generateRandomString(32) + getTimestamp().toString());
}
