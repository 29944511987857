export const sellButtons = [
  `button.product-form__submit`,
  "button.button--addToCart",
  "button.ProductForm__AddToCart",
  `button.button--addToCart`,
  `button.ProductForm__AddToCart`,
  `button.product-form__add-button`,
  `button#add-to-cart`,
  `button.add-to-cart-btn`,
  `button.add-to-cart`,
  `button#AddToCartText`,
  `button#AddToCart`,
  `input[name="add"]`,
  `button[name='add']`,
  `button.single_add_to_cart_button`,
  `.product-form__add-button`,
  `button[data-action=add-to-cart]`,
  `button.product-form__add-button`,
  `.product-form__add-button`,
  `.add-to-cart`,
  `button.product-buy-buttons--primary`,
  `.js-product-add-to-cart`,
  `button.product-form__add-button .loader-button__text`,
];

export const checkoutButtons = [
  "button.cart__checkout-button",
  `button[name="checkout"]`,
  "button.shopify-payment-button__button",
  "cart__checkout-button",
  'form[action="/checkout"] [type="submit"]'
];

// Join all selectors into a single string separated by commas
export const sellButtonsSelector = sellButtons.join(",");
export const checkoutButtonsSelector = checkoutButtons.join(",");
