export default async function fetchProductData(href) {
    const productUrl = href.includes("?")
        ? href.split("?")[0] + ".js"
        : href + ".js";

    try {
        const response = await fetch(productUrl);
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        throw new Error(`Request failed: ${error.message}`);
    }
}
