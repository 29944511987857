import initPageViewListener from "./tracker/listeners/PageViewListener";
import initAddToCartListener from "./tracker/listeners/AddToCartListener";
import initGoToCheckoutListener from "./tracker/listeners/GoToCheckoutListener";
import Tracker from "./tracker/Tracker";
import Context from "./tracker/data/Context";

(function(w) {
    if (! window?.SocialMediaPixelData?.pixels?.length) {
        return;
    }

    const init = () => {
        const context = new Context(
            window.location.href,
            document.referrer,
            navigator.userAgent
        );
        const tracker = new Tracker(window?.SocialMediaPixelData?.pixels, context, null);

        initPageViewListener(tracker);
        initAddToCartListener(tracker);
        initGoToCheckoutListener(tracker);
    };

    // Start tracking on page load
    w.onload = function () {
        init();
    };
})(window);
