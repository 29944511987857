import AbstractPixel from "./AbstractPixel";
import PageViewEvent from "../../tracker/events/PageViewEvent";
import ProductViewEvent from "../../tracker/events/ProductViewEvent";
import AddToCartEvent from "../../tracker/events/AddToCartEvent";
import InitiateCheckoutEvent from "../../tracker/events/InitiateCheckoutEvent";
import {getTimestamp} from "../../helpers/time";
import {normalizePrice} from "../../helpers/price";
import {insertScriptInHead} from "../../helpers/dom";
import {sendDataToServer} from "../../helpers/server-side";
import CheckoutCompletedEvent from "../../tracker/events/CheckoutCompletedEvent";
import SHA256 from "../../helpers/sha256";
import {trim} from "../../helpers/strings";

/**
 * @see TikTok Documentation: https://business-api.tiktok.com/portal/docs?id=1739585702922241
 */
export default class TikTok extends AbstractPixel {

    /**
     * @param dispatcher
     */
    subscribe(dispatcher) {
        dispatcher.listen(PageViewEvent, this.onPageView);
        dispatcher.listen(ProductViewEvent, this.onProductViewEvent);
        dispatcher.listen(AddToCartEvent, this.onAddToCartEvent);
        dispatcher.listen(InitiateCheckoutEvent, this.onInitiateCheckoutEvent);
        dispatcher.listen(CheckoutCompletedEvent, this.onCheckoutCompletedEvent);
    }

    /**
     * @private
     * @param {AbstractEvent} event
     */
    regenerateEventId(event) {
        event.eventId = Math.floor(100000000000 + Math.random() * 900000000000).toString();
    }

    /**
     * @public
     */
    initPixel() {
        /**
         * Warning! For optimal ad performance, Meta recommend that advertisers implement
         * the Conversions API (with AccessToken) alongside their Meta Pixel (without AccessToken).
         * Deduplication by `Event ID`
         */
        this.pixelCollection.duplicateServerSideEventsAsClientSideEvents();

        if (! this.pixelCollection.hasClientSide()) {
            return;
        }

        let code =
            `!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
            var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
            ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};`;

        this.pixelCollection.onlyClientSide().forEach(pixel => {
            code += ` ttq.load('${pixel.code}');`;
        });

        code += `}(window, document, 'ttq');`;

        const userData = this.generateUserData();

        code += `ttq.identify(${JSON.stringify(userData)});`;

        insertScriptInHead(code);
    }

    /**
     * @param {PageViewEvent} event
     */
    onPageView(event) {
        // client-side
        if (this.pixelCollection.hasClientSide()) {
            insertScriptInHead("ttq.page();");
        }

        // server-side
        if (this.pixelCollection.hasServerSide()) {
            this.sendToServer('Browse', event.eventId, {url: event.url});
        }
    }

    /**
     * @param {ProductViewEvent} event
     */
    onProductViewEvent(event) {
        const product = event.product;

        const payload = {
            contents: [{
                content_id: `${product.id}`,
                content_name: product.title,
                content_category: 'product',
                price: normalizePrice(product.price),
                brand: product['vendor'] || ''
            }],
            content_type: 'product_group',
            value: normalizePrice(product.price),
            currency: event.currency
        };

        this.push(
            'ViewContent',
            event.eventId,
            payload
        );
    }

    /**
     *
     * @param {AddToCartEvent} event
     */
    onAddToCartEvent(event) {
        const cartItem = event.item;
        const cart = event.cart;

        const payload = {
            contents: [{
                content_id: `${cartItem.product_id}`,
                content_name: cartItem.product_title,
                quantity: cartItem.quantity,
                price: normalizePrice(cartItem.price),
            }],
            content_type: 'product_group',
            value: normalizePrice(cartItem.price),
            currency: cart.currency
        };

        this.push(
            'AddToCart',
            event.eventId,
            payload
        );
    }

    /**
     * @param {InitiateCheckoutEvent} event
     */
    onInitiateCheckoutEvent(event) {
        const cart = event.cart;

        const contents = cart.items.map((cartItem) => {
            return {
                content_id: `${cartItem.product_id}`,
                content_type: 'product_group',
                content_name: cartItem.product_title,
                quantity: cartItem.quantity,
                price: normalizePrice(cartItem.price),
            };
        });

        const payload = {
            contents: contents,
            value: normalizePrice(cart.total_price),
            currency: cart.currency
        };

        this.push(
            'InitiateCheckout',
            event.eventId,
            payload
        );
    }

    /**
     * @param {CheckoutCompletedEvent} event
     */
    onCheckoutCompletedEvent(event) {
        const shopifyCheckoutEvent = event.shopifyCheckoutCompletedEvent;

        const contents = shopifyCheckoutEvent.data.checkout.lineItems.map((lineItem) => {
            return {
                content_id: `${lineItem.variant.product.id}`,
                content_type: 'product_group',
                content_name: lineItem.variant.product.title,
                quantity: lineItem.quantity,
                price: lineItem.finalLinePrice.amount,
            };
        });

        const payload = {
            contents: contents,
            currency: shopifyCheckoutEvent.data.checkout.totalPrice.currencyCode,
            value: shopifyCheckoutEvent.data.checkout.totalPrice.amount,
        };

        this.push('CompletePayment', event.eventId, payload);
    }

    /**
     * @private
     * @return {{external_id: (string|*)}}
     */
    generateUserData() {
        return {
            external_id: this.resolveUserId(),
            phone: this.userData?.phone ? SHA256(trim(this.userData.phone.toLowerCase())) : '',
            phone_number: this.userData?.phone ? SHA256(trim(this.userData.phone.toLowerCase())) : '',
            email: this.userData?.email ? SHA256(trim(this.userData.email.toLowerCase())) : '',
        };
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    push(eventName, eventId, payload) {
        // client-side
        if (this.pixelCollection.hasClientSide()) {
            this.writeToDOM(eventName, eventId, payload);
        }

        // server-side
        if (this.pixelCollection.hasServerSide()) {
            this.sendToServer(eventName, eventId, payload);
        }
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    writeToDOM(eventName, eventId, payload) {
        /**
         * Only one ttq.track() method call is needed for all client-side pixels
         */
        const code =
            `ttq.track('${eventName}', ${JSON.stringify(payload)}, {event_id:'${eventId}'});`;

        insertScriptInHead(code);
    }

    /**
     * @private
     * @param {string} eventName
     * @param {string} eventId
     * @param {Object} payload
     */
    sendToServer(eventName, eventId, payload) {
        const userData = this.generateUserData();

        this.pixelCollection.onlyServerSideSide().forEach(pixel => {
            const body = {
                event_source: "web",
                event_source_id: pixel.code,
                data: [{
                    event: eventName,
                    event_time: getTimestamp(),
                    event_id: eventId,
                    user: {
                        user_agent: this.context.userAgent,
                        ip: 0,
                        ...userData
                    },
                    page: {
                        url: this.context.url,
                        referrer: this.context.referrer
                    },
                    properties: payload
                }],
            };

            if (pixel.testCode !== "") {
                body.test_event_code = pixel.testCode;
            }

            sendDataToServer(body, pixel.type, pixel.code, pixel.token);
        });
    }
}
