/**
 * @param {Array} array
 * @param {Function} keySelector
 * @return {Object}
 */
export function groupBy(array, keySelector) {
    return array.reduce((result, item) => {
        const key = keySelector(item);

        if (!result[key]) {
            result[key] = [];
        }

        result[key].push(item);

        return result;
    }, {});
}
