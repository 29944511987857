// Backend server host
const server = "process.env.APP_URL";

/**
 * @param {Object} payload
 * @param {string} type
 * @param {string} pixel
 * @param {string} token
 * @return {Promise<void>}
 */
export async function sendDataToServer(payload, type,  pixel, token) {
    fetch(`${server}s`, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            payload: payload,
            type: type,
            pixel: pixel,
            token: token
        })
    })
        .then(response => {
            if (!response.ok) {
                console.error(`Request failed with status ${response.status}`);
            }
            return response.json();
        })
        .catch(error => {
            console.error("Request failed", error);
        });
}
