export default class EventDispatcher {
    constructor() {
        this._listeners = {};
    }

    /**
     * @param {AbstractEvent} event
     * @param {Function} handler
     */
    listen(event, handler) {
        const eventName = this.#getName(event);

        if (this._listeners[eventName]) {
            this._listeners[eventName].push(handler);
        } else {
            this._listeners[eventName] = [ handler ];
        }
    }

    /**
     *
     * @param {AbstractEvent} event
     * @return {boolean}
     */
    hasListeners(event) {
        return this._listeners[this.#getName(event)] !== undefined;
    }

    /**
     * @param {AbstractEvent} event
     * @param {Object} thisArg
     */
    dispatch(event, thisArg) {
        const handlers = this._listeners[this.#getName(event)];
        if (handlers) {
            for (let i = 0; i < handlers.length; i++) {
                handlers[i].call(thisArg, event);
            }
        }
    }

    /**
     * @private
     * @param event
     * @return {string}
     */
    #getName(event) {
        if (typeof event === 'object') {
            return event.constructor.name;
        }

        return event.name;
    }
}
